import { Divider, Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { BoldText, Text } from "../../components/Text";
import ChangePassword from "./ChangePassword";
import { Modal } from "@mui/material";
import ChangeAddress from "../Profile/ChangeAddress";
import ChangeInformation from "../Profile/ContactInformation";
import { accountAction } from "../../services/myAccount";
import { useDispatch, useSelector } from "react-redux";
import PersonIcon from "../../assets/images/svg/person";
import MailIcon from "../../assets/images/svg/Mail";
import { IconButton } from "@mui/material";
import PhoneIcon from "../../assets/images/svg/Phone";
import MapIcon from "../../assets/images/svg/Map";
import LocationIcon from "../../assets/images/svg/Location";
import Location1Icon from "../../assets/images/svg/Location1";
import HomeIcon from "../../assets/images/svg/Home";
import FlagIcon from "../../assets/images/svg/Flag";
import ArrowLeft from "../../assets/images/svg/ArrowLeft";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SnackBar from "../../components/Snackbar";

const Profile = () => {
  const account = useSelector((state) => state.account.merchant);
  const dispatch = useDispatch();
  const [showAddressModal, toggleAddressModal] = useState(false);
  const [showInformationModal, toggleInformationModal] = useState(false);
  const [profile, setProfile] = useState({});
  const [showChangePassword, toggleChangepassword] = useState(false);
  const [snackbar, setSnackbar] = useState(null)
  const setState = (value, key) => {
    setProfile({ ...profile, [key]: value });
  };

  useEffect(() => {
    dispatch(accountAction());
  }, [dispatch]);

  useEffect(() => {
    setProfile({
      merchant_name: account?.contact?.merchant_name || "",
      contact_person: account?.contact?.contact_person || "",
      email: account?.contact?.email || "",
      phone: account?.contact?.phone || "",
      address_line1: account?.address?.address_line_1 || "",
      address_line2: account?.address?.address_line_2 || "",
      city: account?.address?.city || "",
      state: account?.address?.state || "",
      country: account?.address?.country || "",
      postal_code: account?.address?.postal_code || "",
    });
  }, [account]);

  const callback = (status) => {
    console.log("statusss", status)
    if (status) {
      setSnackbar({ message: 'Address updated successfully', severity: 'success' })
    }
    toggleAddressModal(false);
  }

  const contactCallback = (status) => {
    if (status) {
      setSnackbar({ message: 'Contact Information updated successfully', severity: 'success' })
    }
    toggleInformationModal(false);
  }

  const changeCallback = (status) => {
    console.log("statusstatus", status)
    if (status) {
      setSnackbar({ message: 'password updated successfully', severity: 'success' })
    }
    toggleChangepassword(false);
  }
  const openAddressModal = () => {
    toggleAddressModal(true);
  };
  const onCloseAddressModal = () => {
    toggleAddressModal(false);
  };

  const openInformationModal = () => {
    toggleInformationModal(true);
  };
  const onCloseInformationModal = () => {
    toggleInformationModal(false);
  };

  return (
    <Grid
      container
      mb={"50px"}
    >
      <div
        container
        p={2}
        sx={{
          overflow: "auto",
          height: "calc(100vh - 120px)",
        }}
      >
        <Grid
          container
          sx={{
            borderRadius: "15px",
            backgroundColor: "white"
          }}
        >
          <Grid
            container
            sx={{
              justifyContent: "space-between"
            }}
          >
            <Grid
              container
              sx={{
                backgroundColor: "#FFF1ED",
                padding: "10px",
                borderTopLeftRadius: "10px",
                borderTopRightRadius: "10px",
              }}
            >
              <BoldText
                label="My Account"
                sx={{
                  fontSize: 18
                }}
              />
            </Grid>

            <BoldText
              label="Contact Information"
              sx={{
                fontSize: 16,
                marginLeft: "20px",
                marginTop: "10px"
              }}
            />
            <Grid
              sx={{
                marginRight: "15px",
                marginTop: "10px",
                borderRadius: "20px",
              }}
            >
              <Button
                label="Edit"
                onClick={openInformationModal}
              />
              <ToastContainer
                position="bottom-center"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="colored"
              />
            </Grid>
          </Grid>
          <Grid
            container
          >
            <Grid
              item
              xs={12}
            >
              <Grid
                container
                spacing={2}
                p={1}
                justifyContent="space-between"
              >
                <Grid
                  item
                  xs={6}
                  sm={6}
                >
                  <Input
                    sx={{
                      backgroundColor: "#EDEDED",
                      borderRadius: "10px",
                      paddingRight: "15px",
                    }}
                    disabled
                    value={profile.merchant_name}
                    onChange={(value) => setState(value, "merchant_name")}
                    placeholder="Merchant Name"
                    endAdornment={<PersonIcon />}
                  />
                </Grid>
                <Grid
                  item
                  xs={6}
                  sm={6}
                >
                  <Input
                    sx={{
                      backgroundColor: "#EDEDED",
                      borderRadius: "10px",
                      paddingRight: "15px",
                    }}
                    disabled
                    value={profile.contact_person}
                    onChange={(value) => setState(value, "contact_person")}
                    placeholder="Contact Person"
                    endAdornment={<PersonIcon />}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                p={1}
                justifyContent="space-between"
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Input
                    sx={{
                      backgroundColor: "#EDEDED",
                      borderRadius: "10px",
                      paddingRight: "15px",
                    }}
                    disabled
                    value={profile.email}
                    onChange={(value) => setState(value, "email")}
                    placeholder="Email"
                    endAdornment={<MailIcon />}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Input
                    sx={{
                      backgroundColor: "#EDEDED",
                      borderRadius: "10px",
                      paddingRight: "15px",
                    }}
                    disabled
                    value={profile.phone}
                    onChange={(value) => setState(value, "phone")}
                    placeholder="Phone"
                    endAdornment={<PhoneIcon />}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>

        <Grid
          container
          pt={1}
          sx={{
            border: "2px solid white",
            borderRadius: "10px",
            marginTop: "10px",
            backgroundColor: "white",
          }}
        >
          <Grid>
            <Grid
              container
              sx={{
                justifyContent: "space-between",
                alignItems: "center",
                backgroundColor: "white",
                paddingBottom: "5px",
              }}
            >
              <Grid
                sx={{
                  marginLeft: "20px"
                }}
              >
                <BoldText
                  label="Address"
                  sx={{
                    fontSize: 16
                  }}
                />
              </Grid>

              <Grid
                sx={{
                  marginRight: "15px"
                }}>
                <Button
                  label="Edit"
                  onClick={openAddressModal}
                />
              </Grid>
            </Grid>

            <Divider
              sx={{
                borderColor: "var(--border-color)",
                width: "100%",
                height: "4px",
              }}
              flexItem
            />

            <Grid
              container
            >
              <Grid
                container
                spacing={2}
                p={1}
                justifyContent="space-between"
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Input
                    sx={{
                      backgroundColor: "#EDEDED",
                      borderRadius: "10px",
                      paddingRight: "15px",
                    }}
                    disabled
                    value={profile.address_line1}
                    onChange={(value) => setState(value, "address_line1")}
                    placeholder="Address line 1"
                    endAdornment={<LocationIcon />}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Input
                    sx={{
                      backgroundColor: "#EDEDED",
                      borderRadius: "10px",
                      paddingRight: "15px",
                    }}
                    disabled
                    value={profile.address_line2}
                    onChange={(value) => setState(value, "address_line2")}
                    placeholder="Address line 2"
                    endAdornment={<LocationIcon />}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                p={1}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Input
                    sx={{
                      backgroundColor: "#EDEDED",
                      borderRadius: "10px",
                      paddingRight: "15px",
                    }}
                    disabled
                    value={profile.city}
                    onChange={(value) => setState(value, "city")}
                    placeholder="City"
                    endAdornment={<Location1Icon />}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Input
                    sx={{
                      backgroundColor: "#EDEDED",
                      borderRadius: "10px",
                      paddingRight: "15px",
                    }}
                    disabled
                    value={profile.state}
                    onChange={(value) => setState(value, "state")}
                    placeholder="State"
                    endAdornment={<HomeIcon />}
                  />
                </Grid>
              </Grid>

              <Grid
                container
                spacing={2}
                p={1}
              >
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Input
                    sx={{
                      backgroundColor: "#EDEDED",
                      borderRadius: "10px",
                      paddingRight: "15px",
                    }}
                    disabled
                    value={profile.country}
                    onChange={(value) => setState(value, "country")}
                    placeholder="Country"
                    endAdornment={<FlagIcon />}
                  />
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={6}
                >
                  <Input
                    sx={{
                      backgroundColor: "#EDEDED",
                      borderRadius: "10px",
                      paddingRight: "15px",
                    }}
                    disabled
                    value={profile.postal_code}
                    onChange={(value) => setState(value, "postal_code")}
                    placeholder="Zip/Postal code"
                    endAdornment={<MapIcon />}
                  />
                </Grid>
              </Grid>
            </Grid>
            {/* </Grid> */}
          </Grid>
        </Grid>

        <Grid
          item
          xs={12}
          sm={4}
          md={5}
        >

        </Grid>
      </div>

      <Grid
        container
        sx={{
          backgroundColor: "white",
          // padding: "15px ",
          borderRadius: "15px",
          marginTop: "10px",
          border: "2px solid biege",
          paddingLeft: '20px',

          // alignItems:'center'
        }}
        p={1}
        justifyContent={"space-between"}
      >

        <Text
          label="Change password"
          endAdornment={<ArrowLeft />}
          onClick={() => toggleChangepassword(true)}
          sx={{
            textAlign: "left",
            textDecoration: "none",
            cursor: "pointer",
            border: "2px solid biege",
            borderRadius: "15px",
            backgroundColor: "white",
            marginTop: "10px",
            color: '#000000',
            fontSize: 16,
            fontWeight: 700,
            fontFamily: 'Poppins'
          }}
        />
        <IconButton
          onClick={() => toggleChangepassword(true)}>
          <ArrowLeft />
        </IconButton>
      </Grid>

      {
        showChangePassword && (
          <ChangePassword
            changeCallback={changeCallback}
            open={showChangePassword}
            handleClose={() => toggleChangepassword(false)}
          />
        )
      }
      <Modal
        open={showAddressModal}
        onClose={onCloseAddressModal}>
        <ChangeAddress
          callback={callback}
          onClose={onCloseAddressModal}
        />
      </Modal>
      <Modal
        open={showInformationModal}
        onClose={onCloseInformationModal}
      >
        <ChangeInformation
          callback={contactCallback}
          onClose={onCloseInformationModal}
        />
      </Modal>
      {
        snackbar &&
        <SnackBar
          data-qaid="taxeslist_snackbar"
          open={true}
          setOpen={() => setSnackbar(null)}
          severity={snackbar.severity}
          message={snackbar.message}
        />
      }
    </Grid>
  );
};
export default Profile;
