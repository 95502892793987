const initialState = {
    redemptions:[],
    customers:[]
}

const reports = (state = initialState, action) => {
    switch (action.type) {
        case "SET_REPORT_REDEMPTONS": return {...state,redemptions:action.payload}
        case "SET_REPORT_CUSTOMERS":return {...state,customers:action.payload}
        default: return { ...state}
    }
}
export default reports