import { DialogActions, Grid } from "@mui/material";
import "react-toastify/dist/ReactToastify.css";
import { useState } from "react";
import * as Yup from "yup";

import { BootstrapDialogTitle } from "../../components/BootstarpDialog";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { BoldText } from "../../components/Text";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
// import { contactAction } from "../../services/myAccount";
import PersonIcon from "../../assets/images/svg/person";
import PhoneIcon from "../../assets/images/svg/Phone";
import { contactAction } from "../../services/myAccount";
import Error from "../../components/Error";

//
const countryCodes = [
  {
    value: "001",
    code: "+1",
    country: "USA",
  },
  {
    value: "091",
    code: "+91",
    country: "India",
  },
];
const ContactInformation = ({ onClose, callback }) => {
  const account = useSelector((state) => state.account.merchant);
  // const [state, setStat] = useState("");

  const [errors, setErrors] = useState("");
  const dispatch = useDispatch();
  const [profile, setProfile] = useState({
    merchant_name: account?.contact?.merchant_name || "",
    contact_person: account?.contact?.contact_person || "",
    email: account?.contact?.email || "",
    phone: account?.contact?.phone || "",
    countryCode: account?.contact?.country_code || "",
  });
  console.log(profile);
  const setState = (value, key) => {
    setProfile({ ...profile, [key]: value });
  };
  //
  const handleCountryCodeChange = (event) => {
    const countryCode = event.target.value;
    console.log(countryCode);
    setState(countryCode, "countryCode");
  };

  const onFieldChange = (field, value) => {
    setProfile({ ...profile, [field]: value });
    setErrors({ ...errors, [field]: null });
  };

  const handlePhoneNumberKeyPress = (event) => {
    // Allow only numeric characters and Backspace/Delete
    const isValidKey = /^[0-9\b]+$/.test(event.key);
    if (!isValidKey) {
      event.preventDefault();
    }
  };

  const onSubmit = () => {
    const schema = Yup.object({
      merchant_name: Yup.string().required(" *Please enter merchant name"),
      contact_person: Yup.string().required(" *Please enter contact person"),
      phone: Yup.string()
        .length(10, " *phone number must be 10 digits")
        .matches(/^[0-9]{10}$/, " *Phone number is not valid")
        .required(" *Please enter phone number"),
    });
    schema
      .validate(profile, { abortEarly: false })
      .then(() => {
        dispatch(
          contactAction(
            {
              merchant_name: profile.merchant_name,
              contact_person: profile.contact_person,
              email: profile.email,
              country_code: profile.countryCode,
              phone: profile.phone,
            },
            (status) => {
              console.log("Valid");
              callback(status);
              onClose(true);
            }
          )
        );
      })
      .catch((err) => {
        let error = {};
        err.inner.forEach((e) => {
          error = { ...error, [e.path]: e.message };
        });
        setErrors(error);
      });
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      sx={{
        height: "100vh",
        backgroundPosition: "center",
        backgroundSize: "cover !important",
      }}
      alignItems="center"
      alignSelf={"center"}
    >
      <Grid
        container
        sx={{
          width: 330,
          marginTop: 3,
          backgroundColor: "white",
          borderRadius: "15px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >
        <Grid
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            background: "#FFF1ED",
            borderTopLeftRadius: "10px",
            borderTopRightRadius: "10px",
            width: "100%",
            height: "50px",
            paddingLeft: "30px",
            padding: "20px",
          }}
        >
          <BoldText
            label="Update Contact Information"
            sx={{
              fontSize: 16,
              fontFamily: "Poppins",
              fontWeight: 600,
            }}
          />
          <BootstrapDialogTitle
            id="customized-dialog-title"
            onClose={onClose}
          ></BootstrapDialogTitle>
        </Grid>

        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15px",
            width: 320,
            paddingLeft: "10px",
            marginTop: "15px",
          }}
        >
          <Grid>
            <Input
              autoFocus
              placeholder={"Merchant name"}
              value={profile.merchant_name}
              onChange={(value) => {
                setState(value, "merchant_name");
                setErrors({ ...errors, merchant_name: null });
              }}
              sx={{
                borderRadius: 3,
                backgroundColor: "#EDEDED",
                paddingRight: "10px",
              }}
              endAdornment={<PersonIcon />}
            />
            <Error error={errors.merchant_name} />
          </Grid>
          <Grid>
            <Input
              autoFocus
              placeholder={"Contact Person"}
              value={profile.contact_person}
              onChange={(value) => {
                setState(value, "contact_person");
                setErrors({ ...errors, contact_person: null });
              }}
              sx={{
                borderRadius: 3,
                backgroundColor: "#EDEDED",
                paddingRight: "10px",
              }}
              endAdornment={<PersonIcon />}
            />
            <Error error={errors.contact_person} />
          </Grid>

          <Grid>
            {/* <Input
              autoFocus
              value={profile.phone}
              placeholder={"Mobile No"}
              onChange={(value) => {
                setState(value, "phone");
                setErrors({ ...errors, phone: null });
              }}
              sx={{
                borderRadius: 3,
                backgroundColor: "#EDEDED",
                paddingRight: "10px",
              }}
              endAdornment={<PhoneIcon />}
            /> */}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                marginBottom: "10px",
                gap: "4px",
                outline: 'none'
              }}
            >
              <select
                id="countryCode"
                value={profile.countryCode}
                onChange={handleCountryCodeChange}
                style={{
                  padding: "12px",
                  borderRadius: "12px",
                  backgroundColor: "#F5F5F5",
                  outline: 'none'
                }}
              >
                {countryCodes.map((country) => {
                  return (
                    <option value={country.value} key={country.value}>
                      {country.code}
                    </option>
                  );
                })}
              </select>

              <Input
                id="phoneNumber"
                value={profile.phone}
                placeholder="Phone number"
                error={errors.phone}
                onChange={(value) => onFieldChange("phone", value)}
                onKeyPress={handlePhoneNumberKeyPress}
                sx={{
                  borderRadius: 3,
                  backgroundColor: "#EDEDED",
                  paddingRight: "10px",
                  // outline:'none'

                }}
                endAdornment={<PhoneIcon />}

              />
            </div>

            <Error error={errors.phone} />
          </Grid>

          <Grid>
            <DialogActions>
              <Button
                label="Update Information"
                onClick={onSubmit}
                color="var(--primary-btn-bg-color) !important"
                sx={{
                  minWidth: 300,
                  height: 35,
                  padding: "10px !important",
                  marginLeft: "10px",
                  borderRadius: "12px !important",
                  marginBottom: "10px",
                }}
              ></Button>
            </DialogActions>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ContactInformation;
