const urls = {
  login: "login",
  forgot: "forgot",
  reset: "reset",
  dashboard: "dashboard",
  sportsFixtures:"fixture/sports",
  upcomingFixtures: "fixture/upcoming",
  pastFixtures: "fixture/past",
  fixture: "fixture/",
  fixture_toggle_status: "fixture/toggle_status",
  redemption_reports: "report/redemptions",
  customer_reports: "report/customers",
  account: "account/",
  contactUpdate: "account/contact",
  addressUpdate: "account/address",
  passwordUpdate: "account/update_password",
  logout: 'logout',
  redeem_code: "account/redeem_code",
  leaderboard: "fixture/leaderboard",
  notifications: 'notification'
};
export default urls;
