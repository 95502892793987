import React from "react";

const HomeIcon = ({ size = 24, color = "#9F9F9F", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
     <path d="M4.00354 12.1013H18.5351C18.7123 12.1013 18.8642 11.9495 18.8642 11.7722V10.5064C18.8642 10.3291 18.7123 10.1772 18.5351 10.1772H17.2186C16.6868 7.5949 14.4844 5.59496 11.8009 5.36712V3.18992H14.5603C14.7629 3.18992 14.8895 2.93669 14.7629 2.78482L13.8767 1.59496L14.7629 0.4051C14.8895 0.227801 14.7629 0 14.5603 0L11.1173 0.000176592C10.8895 0.000176592 10.7376 0.177476 10.7376 0.379847V5.39246C8.05417 5.62026 5.85161 7.62033 5.31997 10.2025H4.00348C3.82618 10.2025 3.67432 10.3544 3.67432 10.5317V11.7975C3.67432 11.9496 3.82619 12.1014 4.00348 12.1014L4.00354 12.1013Z" fill="#9F9F9F"/>
<path d="M22.5097 23.5207C22.0541 22.4827 20.0793 21.5967 17.3199 21.0397V13.293H5.21877V21.0397C2.45935 21.5967 0.484638 22.4827 0.0289463 23.5207C-0.0722417 23.7485 0.104881 24.0017 0.358111 24.0017H22.1807C22.4339 24.0017 22.611 23.7485 22.5099 23.5207H22.5097ZM13.9022 15.0651C13.9022 14.9384 14.0034 14.8625 14.1048 14.8625H15.2694C15.396 14.8625 15.472 14.9637 15.472 15.0651V16.2297C15.472 16.3563 15.3708 16.4322 15.2694 16.4322H14.1048C13.9782 16.4322 13.9022 16.3311 13.9022 16.2297V15.0651ZM13.9022 18.0271C13.9022 17.9004 14.0034 17.8245 14.1048 17.8245H15.2694C15.396 17.8245 15.472 17.9257 15.472 18.0271V19.1917C15.472 19.3183 15.3708 19.3942 15.2694 19.3942H14.1048C13.9782 19.3942 13.9022 19.2931 13.9022 19.1917V18.0271ZM10.4592 15.0651C10.4592 14.9384 10.5604 14.8625 10.6618 14.8625H11.8264C11.953 14.8625 12.029 14.9637 12.029 15.0651V16.2297C12.029 16.3563 11.9278 16.4322 11.8264 16.4322H10.6618C10.5352 16.4322 10.4592 16.3311 10.4592 16.2297V15.0651ZM7.04163 15.0651C7.04163 14.9384 7.14282 14.8625 7.24418 14.8625H8.40882C8.53543 14.8625 8.61137 14.9637 8.61137 15.0651V16.2297C8.61137 16.3563 8.51018 16.4322 8.40882 16.4322H7.24418C7.11756 16.4322 7.04163 16.3311 7.04163 16.2297V15.0651ZM7.04163 18.0271C7.04163 17.9004 7.14282 17.8245 7.24418 17.8245H8.40882C8.53543 17.8245 8.61137 17.9257 8.61137 18.0271V19.1917C8.61137 19.3183 8.51018 19.3942 8.40882 19.3942H7.24418C7.11756 19.3942 7.04163 19.2931 7.04163 19.1917V18.0271ZM10.4592 20.5334V18.0271C10.4592 17.9004 10.5604 17.8245 10.6618 17.8245H11.8264C11.953 17.8245 12.029 17.9257 12.029 18.0271V20.5334H11.2188H10.4592Z" fill="#9F9F9F"/>


    </svg>
  );
};

export default HomeIcon;