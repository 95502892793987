import CloseIcon from '@mui/icons-material/Close';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import * as React from 'react';
const BootstrapDialog = styled(Dialog)(({ theme }) => ({

    '& .MuiDialogContent-root': {
        padding: theme.spacing(2),
        borderRadius: '10px',
    },
    '& .MuiDialogActions-root': {
        padding: theme.spacing(1),
    },
    '& .MuiDialog-paper': {
        borderRadius: '20px',
        maxWidth: 550,
        justifyContent: 'center',
        alignSelf: 'center',
        alignContent: 'center'
    }
}));

function BootstrapDialogTitle({ children, onClose, ...other }) {

    return (
        <DialogTitle
            data-qaid="bootstrap_dialog_title"
            sx={{
                m: 0,
                p: 2,
                backgroundColor: '#FFF1ED'
            }}
            {...other}
        >

            {children}
            {onClose ? (
                <IconButton
                    data-qaid="bootstrap_dialog_title_close_btn"
                    aria-label="close"
                    onClick={onClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: 'var(--black)',
                    }}
                >
                    <CloseIcon
                        data-qaid="bootstrap_dialog_title_close_btn_icon"
                    />
                </IconButton>
            ) : null}
        </DialogTitle>
    );
}
BootstrapDialogTitle.propTypes = {
    children: PropTypes.node,
    onClose: PropTypes.func.isRequired,
};
export {
    BootstrapDialog,
    BootstrapDialogTitle
};
