
import { DialogContent, DialogActions, Grid } from "@mui/material";

import { useState } from "react";
import * as Yup from "yup";

import {
  BootstrapDialogTitle,
} from "../../components/BootstarpDialog";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { MediumText, BoldText } from "../../components/Text";
import { useDispatch } from "react-redux";
import { forgotAction } from "../../services/forgot";
import { useNavigate } from "react-router-dom";
import MailIcon from "../../assets/images/svg/Mail";
import "react-toastify/dist/ReactToastify.css";

import SnackBar from "../../components/Snackbar";

const ForgotPassword = () => {
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [snackbar, setSnackbar] = useState(null);
  const dispatch = useDispatch()
  const navigate = useNavigate();

  const handleClose = () => {
    navigate("/")
  }

  // const handleSend = () => {

  // Code to handle resetting password
  const handleSend = () => {
    const schema = Yup.object().shape({
      email: Yup.string()
        .email("  *Invalid email")
        .required("   *Please enter your email"),
    });
    schema.validate({ email: email }, { abortEarly: false }).then(() => {
      dispatch(forgotAction({ email }, (status, data) => {
        console.log("data", data)
        if (status) {
          setSnackbar({ message: 'Email sent successfully', severity: 'success' })
        }
        else {
          setSnackbar({ message: data.detail, severity: 'error' })
        }
        setTimeout(() => {
          navigate("/")
        }, 500)

        // if (status) {
        //   toast.success("Email sent Successfully!")
        //   navigate("/")
        // } else {
        //   const e = JSON.parse(data)
        //   setErrors({ email: e.message })
        // }
      }))
      // navigate("/home/dashboard")
    }).catch(err => {
      console.log(err.inner)
      let error = {}
      err.inner.forEach(e => {
        error = { ...error, [e.path]: e.message }
      })
      setErrors(error)

    });
    setErrors({});

  };
  const onEmailChange = (value) => {
    setEmail(value)
    setErrors({ ...errors, email: null })
  }

  // };
  return (
    <>
      {/* <BootstrapDialog open={open} onClose={handleClose} > */}
      <Grid
        container
        justifyContent={"center"}
        sx={{
          height: "100vh",
          // width:'50vw',
          backgroundImage: `url(${require('../../assets/images/Football.png')})`,

          backgroundPosition: 'center',
          backgroundSize: 'cover !important'

        }}
        alignItems="center"
        alignSelf={"center"}>
        <Grid
          container
          sx={{
            width: 315,
            height: 410,
            // backgroundImage:`url(${Image})`,
            backgroundColor: "white",
            borderRadius: "30px",
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <Grid
            item
            xs={12}
            sx={{
              paddingTop: "20px"
            }}
          >
            <Grid
              container
            >
              <Grid
                container
                className="logo"
                sx={{
                  height: 85
                }}
              >

              </Grid>
            </Grid>
          </Grid>
          <BootstrapDialogTitle
            sx={{
              backgroundColor: 'white'
            }}
          >
            <BoldText
              label="Forgot Password?"
              sx={{
                fontSize: 22,
                color: "black",
                fontWeight: 800,
                paddingLeft: 7,

              }}
            />
          </BootstrapDialogTitle>

          {/* <BootstrapDialogTitle sx={{ width: 280 }}>
          <center>
            <Text
              label="Enter your email which is associated with
      your account"
            />{" "}
          </center>
        </BootstrapDialogTitle> */}

          <Grid
            item
            xs={12}
          >
            <Grid>
              <center>
                <MediumText
                  label="Enter your email which is associated with your account"
                  sx={{
                    width: 280,
                    fontWeight: 600,
                    color: "var(--input-label-color)",
                  }}
                />
              </center>
            </Grid>
          </Grid>

          <DialogContent>
            <Input
              autoFocus
              placeholder={"Email"}
              value={email}
              onChange={onEmailChange}
              sx={{
                borderRadius: 3,
                paddingRight: "15px"
              }}
              endAdornment={<MailIcon />}
            />
            {
              errors.email &&
              <span
                style={{
                  color: "#C10000",
                  fontSize: 14,
                  fontWeight: 400,
                  fontFamily: "Poppins",
                  float: "left"
                }}
              >
                {errors.email}
              </span>
            }

          </DialogContent>
          <DialogActions>
            {/* <Button label="Cancel" onClick={handleClose} sx={{backgroundColor:'var(--white)', marginRight:'120px'}} labelStyle={{color:'var(--nav-bg)'}} /> */}
            <Grid
              container
              sx={{
                flexDirection: 'column'
              }}
            >
              <Button
                label="SEND LINK"
                onClick={handleSend}
                // onClick={() => togglehandleSend(true)}>
                sx={{
                  marginLeft: 2,
                  marginRight: 1,
                  minWidth: 265,
                  height: 35,
                  borderRadius: "10px",
                }}
              />

              <Button
                label="Cancel"
                variant="button"
                underline="always"
                onClick={handleClose}
                sx={{
                  backgroundColor: "var(--white)",
                  border: "none",
                  outline: "none",
                  boxShadow: "none",
                  textDecoration: "underline"
                }}
                labelStyle={{
                  color: "#787878"
                }}
              />
            </Grid>
            {/* <Button label="Cancel" onClick={handleClose} sx={{backgroundColor:'var(--white)', marginRight:'120px'}} labelStyle={{color:'var(--nav-bg)'}} /> */}
          </DialogActions>

        </Grid>
        {
          snackbar &&
          <SnackBar
            data-qaid="taxeslist_snackbar"
            open={true}
            setOpen={() => setSnackbar(null)}
            severity={snackbar.severity}
            message={snackbar.message}
          />
        }
      </Grid>
      {/* </BootstrapDialog> */}
    </>
  );
};

export default ForgotPassword;
