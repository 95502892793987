import { Typography } from "@mui/material"

export const Text = ({ label, sx, ...props }) => {
    return (
        <Typography
            {...props}
            sx={{
                fontWeight: 600,
                color: 'var(--input-text-color)',
                fontSize: 14,
                fontFamily: 'var(--poppins)',
                ...sx
            }}>
            {label}
        </Typography>
    )
}

export const BoldText = ({ sx, ...props }) => {
    return (
        <Text
            sx={{
                ...sx,
                fontWeight: 600,
                // fontFamily: 'var(--poppins)'
                fontFamily: 'Poppins',
                color: 'black'
            }}
            {...props}
        />
    )
}
export const MediumText = ({ sx, ...props }) => {
    return (
        <Text
            sx={{

                fontWeight: 500,
                fontFamily: 'var(--nu-medium)',
                ...sx,
            }}
            {...props}
        />
    )
}
export const LightText = ({ sx, ...props }) => {
    return (
        <Text
            sx={{
                ...sx,
                fontWeight: 400,
                fontFamily: 'Poppins'
            }}
            {...props}
        />
    )
}