import axios from 'axios'
import config from '../../src/config.json'
import { store } from '../context'
const AxiosInstance = axios.create({
    baseURL: config.server_url + config.path,
    timeout: 5000,
    headers: {
        'content-type': 'application/json',
        "Accept": "application/json"
    }
})

AxiosInstance.interceptors.response.use(
    (res) => {
        if (res.config.url == 'login') {
            AxiosInstance.defaults.headers.common["token"] = res.data.token
        }
        return res
    },
    (error) => {
        console.log(error.response, "error")
        if (error?.response) {
            const { data, status } = error.response;
            console.log("DATATATTATAA", status)
            if (status === 403 || status === 401) {

                store.dispatch({ type: "UNAUTH" })
            }
            // data["status"] = data.message;
            // if (data.status.indexOf("timeout") !== -1) {
            //     data.status = "No internet connection"
            // }
            // if (data.status.indexOf("Network") !== -1) {
            //     data.status = "No internet connection"
            // }
            return Promise.reject(data)
        } else {
            let data = { status: error.message };
            if (data.status.indexOf("timeout") !== -1) {
                data.status = "No internet connection"
            }
            if (data.status.indexOf("Network") !== -1) {
                data.status = "No internet connection"
            }
            return Promise.reject(data)
        }
    }
)
AxiosInstance.interceptors.request.use(async (req) => {
    console.log("REQ", req)
    try {
        const StoreConfig = await import('../context/store')

        const { store } = StoreConfig;
        let state = store?.getState();

        if (state?.auth?.token && state?.auth?.token != '') {
            const token = state?.auth?.token
            console.log("STTTT", token)
            AxiosInstance.defaults.headers.common["token"] = token
            req.headers['token'] = token;
        }
        return req

    } catch (e) {
        console.log("EEEE", e)
    }
    finally {
        return req
    }

})

export default AxiosInstance