import React from "react";
import { CircularProgress, Backdrop } from '@mui/material'

const Fallback = () => {
    <Backdrop
        sx={{
            color: '#fff',
            zIndex: (theme) => theme.zIndex.drawer + 1
        }}
        open={true}
        onClick={() => { }}
    >
        <CircularProgress
            color="inherit"
        />
    </Backdrop>
}
export default Fallback