const initialState = {
  matches: [
  ],
  loading: false,
  hasEnableMatch: null,
  sports: []
}

const schedules = (state = initialState, action) => {
  switch (action.type) {
    case "SET_MATCHES": return { ...state, matches: action.payload }
    case "SET_LOADING": return { ...state, loading: action.payload }
    case "UPDATE_STATUS": return { ...state, matches: update(state.matches, action.payload) }
    case "SET_ENABLE": return { ...state, hasEnableMatch: action.payload }
    case "SET_SPORTS":return {...state,sports:action.payload}
    default: return { ...state }
  }
}
const update = (list, payload) => {
  let matches = [...list]
  let index = matches.findIndex(e => e.fixture_id === payload.fixture_id);
  if (index !== -1) {
    matches[index]["is_enabled"] = payload.status
  }
  return matches;
}
export default schedules
