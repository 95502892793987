import AxiosInstance from "./AxiosInstance";
import urls from "./urls";
const loginAction = (params, callback) => {
    return (dispatch) => {
        AxiosInstance.post(urls.login, params).then((response) => {
            dispatch({ type: "SET_USER", payload: response.data })
            callback(true, response.data)
        }).catch(error => {
            console.log(error)
            callback(false, error.detail)
        })
    }
}

const resetAction = (params, callback) => {
    return (dispatch) => {
        AxiosInstance.post(urls.reset, params).then((response) => {
            callback(true, response.data)
        }).catch(error => {
            console.log(error)
            callback(false, error.detail)
        })
    }
}

export {
    loginAction,
    resetAction

}