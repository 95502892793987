// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging } from "firebase/messaging";
const firebaseConfig = {
    apiKey: "AIzaSyBrQZl-2lscar5rZDpF_fgd1nj8bDLBuOw",
    authDomain: "spinov-34c32.firebaseapp.com",
    projectId: "spinov-34c32",
    storageBucket: "spinov-34c32.appspot.com",
    messagingSenderId: "780263855272",
    appId: "1:780263855272:web:76e80b713334e8606f9e9d",
    measurementId: "G-PRDCBTV4DB"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);

export default app;
export {
    messaging
}