import { Grid } from "@mui/material"
import useFCM from "../../hooks/useFCM";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { fetchLeaderboard } from "../../services";
const data = [
    {
        username: "krishna",
        profile_pic: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
        rank: 1,
        points: 100
    },
    {
        username: "krishna",
        profile_pic: "https://www.simplilearn.com/ice9/free_resources_article_thumb/what_is_image_Processing.jpg",
        rank: 2,
        points: 90
    }
]
const addOrdinalIndicator = (number) => {
    // Get the last digit and last two digits of the number
    if (number == null) {
        return "-"
    }
    var lastDigit = number % 10;
    var lastTwoDigits = number % 100;

    // If the last two digits are between 11 and 13, return "th"
    if (lastTwoDigits >= 11 && lastTwoDigits <= 13) {
        return "th";
    } else if (lastDigit === 1) { // If the last digit is 1, return "st"
        return "st";
    } else if (lastDigit === 2) { // If the last digit is 2, return "nd"
        return "nd";
    } else if (lastDigit === 3) { // If the last digit is 3, return "rd"
        return "rd";
    } else { // For all other cases, return "th"
        return "th";
    }
}

const LeaderBoard = () => {
    const dispatch = useDispatch()
    const { error, token, messageId } = useFCM()
    const [board, setBoard] = useState([])
    const onFocus = () => {
        console.log("window focus")
        getLeaderBoard()
    }
    const delay = () => {
        return new Promise((resolve) => {
            setTimeout(() => {
                resolve()
            }, 2000)
        })
    }
    const onTabClose = async (ev) => {
        ev.preventDefault();
        await delay()
        return ev.returnValue = 'Are you sure you want to close?'
    }
    useEffect(() => {
        getLeaderBoard()
        window.addEventListener("focus", onFocus);
        window.addEventListener('beforeunload', onTabClose)
        return () => {
            window.removeEventListener("focus", onFocus);
            window.removeEventListener('onbeforeunload', onTabClose);
        }
    }, [])
    const getLeaderBoard = () => {
        dispatch(fetchLeaderboard((status, data) => {
            if (status) {
                setBoard(data?.leaderboard)
            }
        }))
    }

    useEffect(() => {
        getLeaderBoard()
    }, [messageId])
    console.log("board", board)

    return (
        <Grid container sx={{ padding: '20px', justifyContent: 'center' }}>

            {/* <Paper
                sx={{
                    maxWidth: 600,
                }}
            > */}
            <Grid container
                className="card"
                sx={{
                    maxWidth: 800,
                }}
            >
                <Grid container sx={{ fontSize: 20, fontWeight: 'bold' }} className="card-title">
                    Leader Board
                </Grid>
                <Grid
                    container sx={{
                        padding: '10px',
                        maxHeight: 'calc(100vh - 100px)',
                        overflow: 'auto'
                    }}>
                    {
                        board.map((d, index) => {
                            return (
                                <Grid container
                                    key={index}
                                    sx={{
                                        justifyContent: 'space-between',
                                        alignItems: 'center',
                                        padding: '10px',
                                        borderBottom: index == data.length - 1 ? '0px solid #c6c6c6' : '0.5px solid #c6c6c6'
                                    }}
                                >
                                    <Grid item xs={1} className="rank" style={{ position: 'relative', fontSize: 16 }}>
                                        {
                                            d.rank ?
                                                <>
                                                    {`${d.rank}`}
                                                    <span style={{ fontSize: 11 }}>
                                                        {addOrdinalIndicator(d.rank)}
                                                    </span>
                                                </>
                                                :
                                                <>
                                                    -
                                                </>
                                        }

                                    </Grid>
                                    <Grid item xs={9}>
                                        <Grid container
                                            sx={{ alignItems: 'center' }}
                                        >
                                            <img
                                                src={d.profile_url}
                                                style={{
                                                    width: 60,
                                                    height: 60,
                                                    borderRadius: 100,
                                                    marginRight: '10px'
                                                }}
                                                alt={d.username}
                                            />
                                            <div>{d.username}</div>
                                        </Grid>

                                    </Grid>
                                    <Grid item xs={2}>
                                        <div>{d.points} points</div>
                                    </Grid>

                                </Grid>
                            )
                        })
                    }
                </Grid>
            </Grid>
            {/* </Paper> */}
        </Grid>

    )
}

export default LeaderBoard