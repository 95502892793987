import { Button as MButton } from "@mui/material"
import React from "react"
import { Text } from "./Text"
const Button = ({
    label,
    onClick,
    sx,
    labelStyle,
    color = 'var(--primary-btn-bg-color)',
    type,
    ...props
}) => {
    return (
        <MButton
            data-qaid="custom_btn"
            variant="contained"
            type={type}
            onClick={onClick}
            sx={{
                paddingLeft: '5px',
                paddingRight: '5px',
                minWidth: 100,
                textTransform: 'none',
                borderRadius: '12px',
                backgroundColor: sx?.backgroundColor ? sx.backgroundColor : color,
                '&:focus,&:hover': {
                    backgroundColor: sx?.backgroundColor ? sx.backgroundColor : color
                },
                ...sx
            }}
            {...props}
        >
            <Text
                label={label}
                data-qaid="custom_btn_label"
                style={{
                    color: 'var(--white)',
                    fontFamily: 'var(--nu-bold)',
                    ...labelStyle
                }}
            />
        </MButton>
    )
}

const CancelButton = React.memo(({ onClick, label = "Cancel" }) => {
    return (
        <Button
            data-qaid="custom_cancel_btn"
            onClick={onClick}
            label={label}
            sx={{ backgroundColor: 'var(--btn-cancel-color)', marginRight: '15px' }}
            labelStyle={{ color: 'var(--btn-cancel-text)' }} />
    )
})
export default Button
export {
    CancelButton
}
