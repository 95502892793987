import { DialogActions, Grid } from "@mui/material";
import { useState } from "react";
import * as Yup from "yup";
import { BootstrapDialogTitle } from "../../components/BootstarpDialog";
import Button from "../../components/Button";
import Input from "../../components/Input";
import { BoldText } from "../../components/Text";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { addressAction } from "../../services/myAccount";
import Error from "../../components/Error";
const ChangeAddress = ({ onClose, callback }) => {
  const account = useSelector((state) => state.account.merchant);

  const [errors, setErrors] = useState({});
  const dispatch = useDispatch();
  const [profile, setProfile] = useState({
    address_line_1: account?.address?.address_line_1 || "",
    address_line_2: account?.address?.address_line_2 || "",
    city: account?.address?.city || "",
    state: account?.address?.state || "",
    country: account?.address?.country || "",
    postal_code: account?.address?.postal_code || "",
  });

  const setState = (value, key) => {
    setProfile({ ...profile, [key]: value });
  };

  const onSubmit = () => {
    const schema = Yup.object({
      address_line_1: Yup.string().required(" *Please enter your address"),
      address_line_2: Yup.string().required(" *Please enter your address"),
      city: Yup.string().required(" *Please enter your city "),
      state: Yup.string().required(" *Please enter your state "),
      country: Yup.string().required(" *Please enter your country "),
      postal_code: Yup.string().required(" *Please enter your postal code").length(6, " *postal code must be 6 digits")

    });
    schema
      .validate(profile, { abortEarly: false })
      .then(() => {
        dispatch(
          addressAction(
            {
              address_line_1: profile.address_line_1,
              address_line_2: profile.address_line_2,
              city: profile.city,
              state: profile.state,
              country: profile.country,
              postal_code: profile.postal_code,
            },
            (status) => {
              callback(status)
              onClose(true);
            }
          )
        );
      })
      .catch((err) => {
        let error = {};
        err.inner.forEach((e) => {
          error = { ...error, [e.path]: e.message };
        });
        setErrors(error);
      });
  };

  return (
    <Grid
      container
      justifyContent={"center"}
      sx={{
        height: "100vh",
        backgroundPosition: "center",
        backgroundSize: "cover !important",
      }}
      alignItems="center"
      alignSelf={"center"}
    >
      <Grid
        container
        sx={{
          width: 320,
          backgroundColor: "white",
          borderRadius: "15px",
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%,-50%)",
        }}
      >

        <Grid
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            backgroundColor: ' #FFF1ED',
            borderTopLeftRadius: '10px',
            borderTopRightRadius: '10px',
            gap: '20px',
            width: "100%",
            height: '50px'
          }}
        >
          <BoldText
            label="Update Address"
            sx={{
              fontSize: 16,
              color: "black",
              fontWeight: 800,
              paddingLeft: 7
            }}
          />
          <BootstrapDialogTitle
            onClose={onClose}
          >
          </BootstrapDialogTitle>
        </Grid>

        <Grid
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "10px",
            width: 310,
            paddingLeft: "10px",
            marginTop: "10px",
          }}
        >
          <Grid>
            <Input
              autoFocus
              value={profile.address_line_1}
              placeholder={"AddressLine1"}
              onChange={(value) => {
                setState(value, "address_line_1");
                setErrors({ ...errors, address_line_1: null });
              }}
              sx={{
                borderRadius: 3,
                backgroundColor: "#EDEDED"
              }}
            />
            <Error error={errors.address_line_1} />

          </Grid>
          <Grid>
            <Input
              autoFocus
              value={profile.address_line_2}
              placeholder={"AddressLine2"}
              onChange={(value) => {
                setState(value, "address_line_2");
                setErrors({ ...errors, address_line_2: null });
              }}
              sx={{
                borderRadius: 3,
                backgroundColor: "#EDEDED"
              }}
            />
            <Error
              error={errors.address_line_2}
            />

          </Grid>
          <Grid>
            <Input
              autoFocus
              value={profile.city}
              placeholder={"City"}
              onChange={(value) => {
                setState(value, "city");
                setErrors({ ...errors, city: null });
              }}
              sx={{
                borderRadius: 3,
                backgroundColor: "#EDEDED"
              }}
            />
            <Error
              error={errors.city}
            />

          </Grid>
          <Grid>
            <Input
              autoFocus
              value={profile.state}
              placeholder={"State"}
              onChange={(value) => {
                setState(value, "state");
                setErrors({ ...errors, state: null });
              }}
              sx={{
                borderRadius: 3,
                backgroundColor: "#EDEDED"
              }}
            />
            <Error
              error={errors.state}
            />

          </Grid>
          <Grid>
            <Input
              autoFocus
              value={profile.country}
              placeholder={"Country"}
              onChange={(value) => {
                setState(value, "country");
                setErrors({ ...errors, country: null });
              }}
              sx={{
                borderRadius: 3,
                backgroundColor: "#EDEDED"
              }}
            />
            <Error
              error={errors.country}
            />

          </Grid>
          <Grid>
            <Input
              autoFocus
              value={profile.postal_code}
              placeholder={"Zip/Postal Code"}
              onChange={(value) => {
                setState(value, "postal_code");
                setErrors({ ...errors, postal_code: null });
              }}
              sx={{
                borderRadius: 3,
                backgroundColor: "#EDEDED"
              }}
            />
            <Error
              error={errors.postal_code}
            />

          </Grid>
          <Grid>
            <DialogActions>
              <Button
                label="Update Address"
                color="var(--primary-btn-bg-color) !important"
                onClick={onSubmit}

                sx={{
                  marginRight: "6px !important",
                  minWidth: 270,
                  height: 35,
                  borderRadius: "12px !important",
                  marginBottom: '10px'
                }}
              />
            </DialogActions>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ChangeAddress;
