const initialState = {
    email: "",
    name: "",
    unauth: false
}

const auth = (state = initialState, action) => {
    switch (action.type) {
        case "SET_USER": return { ...action.payload, unauth: false }
        case "persist/REHYDRATE": return onRehydrate(action.payload)
        case "LOGOUT": return { ...initialState }
        case "UNAUTH": return { ...state, unauth: true }
        default: return { ...state }
    }
}
const onRehydrate = (payload) => {
    if (payload) {
        return { ...payload.auth }
    }
    return { ...initialState }

}
export default auth