import React from "react";

const FlagIcon = ({ size = 24, color = "#9F9F9F", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
     <path fill-rule="evenodd" clip-rule="evenodd"
      d="M20.6016 12.7079C14.801 14.8412 9.00037 10.5745 3.20018 12.7079V2.04131C9.0008 -0.0919969 14.801 4.17462 20.6016 2.04131V12.7079ZM0 22.9333C0 23.5225 0.477535 24 1.06674 24C1.65582 24 2.13331 23.5225 2.13331 22.9333V1.06674C2.13331 0.477665 1.65577 0 1.06674 0C0.477492 0 0 0.477665 0 1.06674V22.9333Z" 
      fill="#9F9F9F"/>


    </svg>
  );
};

export default FlagIcon;