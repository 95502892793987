import React, { useMemo } from 'react';
import { Snackbar } from '@mui/material';
import { Alert } from '@mui/material';
import MailIcon from '../assets/images/svg/Mail';
import PersonIcon from '../assets/images/svg/person';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
const SnackBar = ({
  open,
  setOpen,
  message,
  duration = 2000,
  severity
}) => {
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    if (setOpen)
      setOpen(false);
  };
  const alertProps = useMemo(() => {
    let backgroundColor;
    let Icon = CheckCircleOutlineIcon;
    let iconColor;
    switch (severity) {
      case 'error':
      case 'warn':
        Icon = HighlightOffIcon;
        backgroundColor = '#FDF3F2';
        iconColor = "#AB1212";
        break;
      default:
        backgroundColor = '#E6F6E7';
        iconColor = "#1C7D26";
        break;
    }

    return {
      icon: <Icon
        fontSize="inherit"
        color={iconColor}
        width={18}
        height={18}
        style={{
          alignItems: 'center'
        }}
      />,
      variant: "outlined",
      elevation: 6,
      style: {
        display: 'flex',
        alignItems: 'center',
        padding: '8px 16px',
        height: '40px',
        backgroundColor,
        border: `2px solid ${iconColor}`
      }
    };
  }, [severity]);

  return (
    <Snackbar
      data-qaid="snackbar_snackbar"
      open={open}
      autoHideDuration={duration}
      onClose={handleClose}
      anchorOrigin={{
        vertical: 'center',
        horizontal: 'center'
      }}
      style={{
        minHeight: 'unset',
        height: 'fit-content'
      }}
    >

      <Alert
        icon={alertProps.icon}
        data-qaid="snackbar_alert"
        {...alertProps}>
        <h4>{message}</h4>
      </Alert>

    </Snackbar>
  );
}

export default SnackBar;
