import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import { Grid, DialogContent, DialogActions } from "@mui/material";
import Input from "../../components/Input";
import Button from "../../components/Button";
import { IconButton, InputAdornment } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { passwordAction } from "../../services/myAccount";
import { BootstrapDialog, BootstrapDialogTitle } from "../../components/BootstarpDialog";
import Error from "../../components/Error";
import moment from "moment";
const op = {
  old: "new",
  new: "old",
  confirm: "new"
}
const ChangePassword = ({ open, handleClose, changeCallback }) => {
  const [oldPassword, setOldPassword] = useState("");
  const [newpassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showPassword, setShowPassword] = useState("");
  const [errors, setErrors] = useState({});
  const dispatch = useDispatch()
  useEffect(() => {
    if (!oldPassword) {
      outFocus("old")
    }
  }, [oldPassword])
  useEffect(() => {
    if (!newpassword) {
      outFocus("new")
    }
  }, [newpassword])
  useEffect(() => {
    if (!confirmPassword) {
      outFocus("confirm")
    }
  }, [confirmPassword])
  const outFocus = (id) => {
    const otherEl = document.getElementById(op[id]);
    if (otherEl) {
      setTimeout(() => {
        otherEl.focus();
        setTimeout(() => {
          const el = document.getElementById(id);
          if (el) {
            el.focus()
          }

        }, 0)
      }, 0)

    }
  }
  const handleClickShowOldPassword = () => {
    setShowOldPassword(!showOldPassword);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword(!showNewPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const handleNewMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const onsetOldPassword = (value) => {
    setOldPassword(value)
    setErrors({ ...errors, oldPassword: null })
  }
  const onsetNewPassword = (value) => {
    setNewPassword(value)
    setErrors({ ...errors, newpassword: null })
  }
  const onsetConfirmPassword = (value) => {
    setConfirmPassword(value)
    setErrors({ ...errors, confirmPassword: null })
  }
  const removeReadOnly = (e) => {
    e.preventDefault();
    const el = e.target

    if (el)
      setTimeout(() => {
        el.removeAttribute('readonly')
      }, 300)

  }

  const onClickInput = (e) => {
    e.preventDefault();
    // const el = e.target;
    // if (el) {
    //   outFocus(el.id)
    // }
    // console.log("removeReadOnly", el.id)
    const otherEl = document.getElementById("hidden");
    if (otherEl) {
      otherEl.focus();
      setTimeout(() => {
        const el = e.target;
        el.focus()
      }, 500)
    }

  }
  const addReadOnly = (e) => {
    const el = e.target
    if (el)
      setTimeout(() => {
        el.setAttribute('readonly', 'true')
      }, 300)
  }
  const onSubmit = () => {
    let passwords = {
      oldPassword,
      newpassword,
      confirmPassword
    }
    const schema = Yup.object({
      oldPassword: Yup.string().required(" *Please enter your old password"),
      newpassword: Yup.string().min(6, '*Password must be atleast 6 characters').required(" *Please enter your new password"),
      confirmPassword: Yup.string().required(" *Please confirm your password").oneOf([Yup.ref('newpassword')], ' *Passwords must match')

    });
    schema
      .validate(passwords, { abortEarly: false })
      .then(() => {
        dispatch(
          passwordAction(
            {
              old_password: oldPassword,
              new_password: newpassword,

            },
            (status, data) => {
              changeCallback(status);
              handleClose(true)
            }
          )
        );
      })
      .catch((err) => {
        let error = {};
        err.inner.forEach((e) => {
          error = { ...error, [e.path]: e.message };
        });
        setErrors(error);
      });
  };

  return (
    <BootstrapDialog
      onClose={handleClose}
      aria-labelledby="customized-dialog-title"
      maxWidth="sm"
      className="rounded-modal"
      sx={{
        borderRadius: '20px'
      }}
      // fullWidth={true}
      open={open}
    >

      <BootstrapDialogTitle
        sx={{
          paddingLeft: 8,
          display: 'flex',
          fontSize: 18,
          fontWeight: 700
        }}
        id="customized-dialog-title"
        onClose={handleClose}
      >
        Change password

      </BootstrapDialogTitle>

      <DialogContent
        sx={{
          borderRadius: '15px'
        }}
      >

        <form>

          <Grid
            container
            spacing={3}
            sx={{
              maxWidth: 320
            }}
          >
            <input type="password" id="hidden" className="hidden" />
            <Grid
              item
              xs={12}
            >

              <Input
                type={showOldPassword ? "text" : oldPassword ? "password" : "text"}
                value={oldPassword}
                className="auto_complete_off"
                id={"old"}
                onFocus={removeReadOnly}
                onBlur={addReadOnly}
                onClick={onClickInput}
                readOnly
                autoComplete={`old-passwordold${moment().unix()}`}
                sx={{
                  paddingRight: "12px",
                  borderRadius: "10px",
                  backgroundColor: '#EDEDED',
                  color: 'black'
                }}
                placeholder="Old Password"
                onChange={onsetOldPassword}

                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      sx={{
                        borderRadius: "4px",
                        width: 45,
                        height: 45,
                      }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowOldPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {
                        showOldPassword ? (
                          <Visibility sx={{ fontSize: 18 }} />
                        ) : (
                          <VisibilityOff sx={{ fontSize: 18 }} />
                        )}
                    </IconButton>
                  </InputAdornment>
                }
              />{" "}
              <Error
                error={errors.oldPassword}
              />

            </Grid>

            <Grid
              item
              xs={12}
            >
              <Input
                type={showNewPassword ? "text" : newpassword ? "password" : "text"}
                value={newpassword}
                id="new"
                className="auto_complete_off"
                onFocus={removeReadOnly}
                onBlur={addReadOnly}
                onClick={onClickInput}
                readOnly
                autoComplete={`new-password${moment().unix()}`}
                sx={{
                  paddingRight: "12px",
                  borderRadius: "10px",
                  backgroundColor: '#EDEDED',
                  color: 'black'
                }}
                placeholder="New Password"
                onChange={onsetNewPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      sx={{
                        borderRadius: "4px",
                        width: 45,
                        height: 45,

                      }}
                      aria-label="toggle password visibility"
                      onClick={handleClickShowNewPassword}
                      onMouseDown={handleNewMouseDownPassword}
                      edge="end"
                    >
                      {
                        showNewPassword ? (
                          <Visibility sx={{ fontSize: 18 }} />
                        ) : (
                          <VisibilityOff sx={{ fontSize: 18 }} />
                        )
                      }
                    </IconButton>
                  </InputAdornment>
                }
              />{" "}
              <Error
                error={errors.newpassword}
              />

            </Grid>

            <Grid
              item
              xs={12}
            >
              <Input

                type={showConfirmPassword ? "text" : confirmPassword ? "password" : "text"}
                value={confirmPassword}
                id="confirm"
                className="auto_complete_off"
                onFocus={removeReadOnly}
                onBlur={addReadOnly}
                onClick={onClickInput}
                readOnly
                autoComplete={`new-password-confirm${moment().unix()}`}
                sx={{
                  paddingRight: "12px",
                  borderRadius: "10px",
                  backgroundColor: '#EDEDED',
                }}
                placeholder="Confirm password"
                onChange={onsetConfirmPassword}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      sx={{
                        //   backgroundColor: "var(--password-icon-bg-color)",
                        borderRadius: "4px",
                        width: 45,
                        height: 45,
                      }}
                      aria-label="toggle password visibility"
                      onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                    >
                      {
                        showConfirmPassword ? (
                          <Visibility sx={{ fontSize: 18 }} />
                        ) : (
                          <VisibilityOff sx={{ fontSize: 18 }} />
                        )
                      }
                    </IconButton>
                  </InputAdornment>
                }
              />{" "}
              <Error
                error={errors.confirmPassword}
              />

            </Grid>

          </Grid>
        </form>
      </DialogContent>
      <DialogActions>
        <Button
          sx={{
            padding: "10px !important",
            marginRight: '15px !important',
            minWidth: '260px ',
            borderRadius: '12px !important',


            marginBottom: '10px'
          }}
          onClick={onSubmit}
          color='var(--primary-btn-bg-color) !important'
          label={"Change Password"}
        />
      </DialogActions>

    </BootstrapDialog>
  );
};

export default ChangePassword;