import {
  Grid,
  Paper,
  TextField,
  Button,
  Typography,
  IconButton,
  InputAdornment,
} from "@mui/material";
import * as Yup from "yup";
import { MediumText, BoldText } from "../../components/Text";
import { useLocation } from "react-router-dom";
import { useMemo, useState } from "react";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch } from "react-redux";
import { resetAction } from "../../services";
import { useNavigate } from "react-router-dom";

export default function ResetPassword({ open, handleClose, resetCallback }) {
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [errors, setErrors] = useState({});
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const token = useMemo(() => {
    const path = location.pathname.replace("/reset/", "");
    return path;
  }, [location]);

  const onSubmit = () => {
    let passwords = {
      password,
      confirmPassword,
    }
    const schema = Yup.object({
      password: Yup.string()
        .min(6, " *Password must be min 6 characters")
        .required(" *Password is required"),
      confirmPassword: Yup.string()
        .oneOf([Yup.ref("password")], " *Passwords must match")
        .required(" *Confirm Password is required"),
    });
    schema
      .validate(passwords, { abortEarly: false })
      .then(() => {
        dispatch(
          resetAction(
            {
              password: password,
              reset_token: token,

            },
            (status, data) => {
              console.log("Valid");
              resetCallback(status);
              handleClose(true)

            }
          )
        );
        navigate("/")
      })
      .catch((err) => {
        let error = {};
        err.inner.forEach((e) => {
          error = { ...error, [e.path]: e.message };
        });
        setErrors(error);
      });

  };

  const onPasswordChange = (event) => {
    const value = event.target.value;
    setPassword(value);
    setErrors({ ...errors, password: null });
  }
  const onConfirmPasswordChange = (event) => {
    const value = event.target.value;
    setConfirmPassword(value);
    setErrors({ ...errors, confirmPassword: null });
  }

  return (
    <Grid
      container
      spacing={2}
      justifyContent="center"
      alignItems="center"
      sx={{
        height: "100vh",
        backgroundImage: `url(${require("../../assets/images/Football.png")})`,
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
        paddingLeft: "60px",
      }}
    >
      <Grid
        item
        xs={12}
        sm={6}
        md={4}
      >
        <Paper
          elevation={3}
          sx={{
            p: 2,
            width: 280,
            borderRadius: 5
          }}
        >
          <form>
            <Grid
              item
              xs={12}
            >
              <Grid
                container
              >
                <Grid
                  container
                  className="logo"
                  sx={{
                    width: 275,
                    height: 85
                  }}
                ></Grid>
              </Grid>
            </Grid>

            <Typography
              label=""
              sx={{
                fontSize: 22,
                color: "black",
                fontWeight: 800,
                paddingLeft: 7
              }}
            />
            <Grid>
              <center>
                <BoldText
                  label="Reset Password"
                  sx={{
                    p: 1,
                    fontSize: 20,
                    fontWeight: 600,
                  }}
                />
              </center>
            </Grid>

            <Grid
              item
              xs={12}
            >
              <Grid>
                <center>
                  <MediumText
                    label="Create your new password so you can login your account"
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: 12,
                      fontWeight: 600,
                      color: "var(--input-label-color)"
                    }}
                  />
                </center>
              </Grid>
            </Grid>
            <Grid
              className="pp"
              sx={{
                '& .MuiFormControl-root': {
                  marginBottom: 0
                }
              }}
            >
              <TextField
                id="password"
                name="password"
                type={showPassword ? "text" : "password"}
                placeholder="New Password"
                onChange={onPasswordChange}
                fullWidth
                margin="normal"
                InputProps={{
                  sx: {
                    borderRadius: "10px",
                    backgroundColor: "#EDEDED",
                    height: 50,
                    fontFamily: 'var(--poppins)'
                  },
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() => setShowPassword(!showPassword)}
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {
                          showPassword ?
                            <Visibility />
                            :
                            <VisibilityOff />
                        }
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              // error={!!errors.password}
              // helperText={errors.password}
              />
              {
                errors.password ? (
                  <span
                    style={{
                      color: "#C10000",
                      fontSize: 14,
                      fontWeight: 400,
                      fontFamily: 'Poppins',
                      float: "left",
                    }}
                  >
                    {errors.password}
                  </span>
                ) : null}
            </Grid>
            <Grid
              sx={{
                '& .MuiFormControl-root': {
                  marginBottom: 0
                }
              }}
            >
              <TextField
                id="confirmPassword"
                name="confirmPassword"
                type={showConfirmPassword ? "text" : "password"}
                placeholder="Confirm Password"
                onChange={onConfirmPasswordChange}
                fullWidth
                margin="normal"
                InputProps={{
                  sx: {
                    borderRadius: "10px",
                    backgroundColor: "#EDEDED",
                    height: 50,
                    fontFamily: 'var(--poppins)'
                  },
                  disableUnderline: true,
                  endAdornment: (
                    <InputAdornment
                      position="end"
                    >
                      <IconButton
                        aria-label="toggle password visibility"
                        onClick={() =>
                          setShowConfirmPassword(!showConfirmPassword)
                        }
                        onMouseDown={(e) => e.preventDefault()}
                        edge="end"
                      >
                        {
                          showConfirmPassword ?
                            <Visibility />
                            :
                            <VisibilityOff />
                        }
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              // error={!!errors.password}
              // helperText={errors.password}
              />
              {errors.confirmPassword ? (
                <span
                  style={{
                    color: "#C10000",
                    fontSize: 14,
                    fontWeight: 400,
                    fontFamily: 'Poppins',
                    float: "left",
                  }}
                >
                  {errors.confirmPassword}
                </span>
              ) : null}
            </Grid>

            <Grid
              sx={{
                paddingBottom: "20px"
              }}
            >
              <Button
                onClick={onSubmit}
                variant="contained"
                sx={{
                  mt: 2,
                  width: 280,
                  backgroundColor: "#FB6C49 !important",
                  borderRadius: 3
                }}
              >
                Confirm
              </Button>
            </Grid>
          </form>
        </Paper>
      </Grid>
    </Grid>
  );
}
