import AxiosInstance from "./AxiosInstance";
import urls from "./urls";
const forgotAction = (params, callback) => {
    return (dispatch) => {
        AxiosInstance.post(urls.forgot, params).then((response) => {
            callback(true, response.data)
        }).catch(error => {
            console.log(error)
            callback(false)
        })
    }
}

export {
    forgotAction
}