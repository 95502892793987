
import React, { Suspense } from "react"
import { createBrowserRouter, RouterProvider, Outlet } from "react-router-dom"
import Fallback from "../components/Fallback"
import ForgotPassword from "../pages/Login/ForgotPassword"
import ResetPassword from "../pages/Login/Resetpassword"
import Profile from '../pages/Profile'
import LeaderBoard from "../pages/LeaderBoard"
const Login = React.lazy(() => import('../pages/Login'))
const Dashboard = React.lazy(() => import('../pages/Dashboard'))
const Schedules = React.lazy(() => import('../pages/Schedules'))
const Reports = React.lazy(() => import('../pages/Reports'))
const Rewards = React.lazy(() => import('../pages/Rewards'))
const Home = React.lazy(() => import('../layouts/Home'))

const config = {
    "path": "/",
    "element": <Outlet />,
    "children": [
        {
            "path": "/",
            "element": <Suspense fallback={<Fallback />}><Login /></Suspense>
        },
        {
            "path": "forgot-password",

            "element": <ForgotPassword />
        },
        {
            "path": "/reset/:token",
            "element": <ResetPassword />

        },
        {
            "path": "/leaderboard",
            "element": <LeaderBoard />
        },
        {
            "path": "home",
            "element": <Suspense fallback={<Fallback />}><Home /></Suspense>,
            "children": [
                {
                    "path": "dashboard",
                    "element": <Dashboard />
                },
                {
                    "path": "schedules",
                    "element": <Schedules />
                },
                {
                    "path": "reports",
                    "element": <Reports />
                },
                {
                    "path": "rewards",

                    "element": <Rewards />

                },
                {
                    "path": "profile",
                    "element": <Profile />
                }
            ]
        }
    ]
}

const router = createBrowserRouter([
    config
])

const RouterConfig = () => <RouterProvider router={router} />

export default RouterConfig