import { useEffect, useState } from "react"
import { messaging } from "../utils/fcm"
import { getToken, onMessage } from "firebase/messaging"
import { useDispatch } from "react-redux"
import { registerFcm } from "../services"
// import { onBackgroundMessage } from 'firebase/messaging/sw'

const useFCM = () => {
    const [token, setToken] = useState(null)
    const [error, setError] = useState(null)
    const [messageId, setMessageId] = useState(null)
    const dispatch = useDispatch()

    useEffect(() => {
        requestPermission();
        onMessage(messaging, (payload) => {
            console.log('Message received. ', payload);
            // ...
            setMessageId(payload.messageId)
        })

    }, [])
    useEffect(() => {
        if (token) {
            console.log("Register token")
            dispatch(registerFcm({ firebase_token: token }, (status) => {
                if (status) {

                }
            }))
        }
    }, [token])
    const requestPermission = () => {
        if (Notification.permission === 'granted') {
            getFCMToken()
        }
        else if (Notification.permission !== 'denied') {
            Notification.requestPermission().then((permission) => {
                if (permission === 'granted') {
                    console.log('Notification permission granted.');
                    getFCMToken()
                }
            })
        }

    }
    const getFCMToken = () => {

        getToken(messaging, { vapidKey: 'BI6uLH-PloDLuDPNLmoYGs8XR6wDK9AQ3uBzUh3X2jJowjqM0ex8-pF59Df6NOqxdd_X1UvweL3mn1C4W_TNnrQ' }).then((currentToken) => {
            if (currentToken) {

                console.log("FCM TOKEN", currentToken)
                setToken(currentToken)
                setError(null)
            } else {
                // Show permission request UI
                setError("No registration token available. Request permission to generate one.")
                console.log('No registration token available. Request permission to generate one.');
                // ...
            }
        }).catch((err) => {
            setError("An error occurred while retrieving token. ")
            console.log('An error occurred while retrieving token. ', err);
            // ...
        });
    }
    return {
        token,
        error,
        messageId
    }
}

export default useFCM