import AxiosInstance from "./AxiosInstance"
import urls from "./urls"

const getSportsFixtures = (params) => {
    return (dispatch) => {
      dispatch({ type: "SET_LOADING", payload: true });
      AxiosInstance.get(urls.sportsFixtures, { params }).then(response => {
        const { data } = response;
        dispatch({ type: "SET_SPORTS" , payload: data.Sports})
        // dispatch({ type: "SET_MATCHES", payload: fixtures });
        dispatch({ type: "SET_LOADING", payload: false });
      }).catch(error => {
        console.log(error);
        // dispatch({ type: "SET_MATCHES", payload: [] });
        dispatch({ type: "SET_LOADING", payload: false });
      });
    };
  };

const getUpcomingFixtures = (params) => {
    return (dispatch) => {
        dispatch({ type: "SET_LOADING", payload: true })
        AxiosInstance.get(urls.upcomingFixtures,{params}).then(response => {
 
            const { data } = response;
            const { fixtures } = data
            dispatch({ type: "SET_MATCHES", payload: fixtures })
            dispatch({ type: "SET_LOADING", payload: false })
        }).catch(error => {
            console.log(error)
            dispatch({ type: "SET_MATCHES", payload: [] })
            dispatch({ type: "SET_LOADING", payload: false })
        })
    }
}
const getPastFixtures = (params) => {
    return (dispatch) => {
        dispatch({ type: "SET_LOADING", payload: true })
        AxiosInstance.get(urls.pastFixtures,{params}).then(response => {
            const { data } = response;
            const { fixtures } = data
            dispatch({ type: "SET_MATCHES", payload: fixtures })
            dispatch({ type: "SET_LOADING", payload: false })
        }).catch(error => {
            console.log(error)
            dispatch({ type: "SET_MATCHES", payload: [] })
            dispatch({ type: "SET_LOADING", payload: false })
        })
    }
}
const getFixtureDetails = (params, callback) => {
    return (dispatch) => {

        AxiosInstance.get(urls.fixture, { params }).then(response => {

            callback(true, response.data)
        }).catch(error => {
            callback(false, error)
        })
    }
}

const toggleFixtureEnable = (params, callback) => {
    return (dispatch) => {
        AxiosInstance.put(urls.fixture_toggle_status, params).then((response) => {

            dispatch({ type: "UPDATE_STATUS", payload: params })
            callback(true, response.data)
            // dispatch(getFixtureDetails({ fixture_id: params.fixture_id }, callback))
        }).catch(error => {
            console.error(error);
            callback(false, error)
        })
    }
}
const fetchLeaderboard = (callback) => {
    return (dispatch) => {
        AxiosInstance.get(urls.leaderboard).then((response) => {
            callback(true, response.data)
        }).catch(error => {
            console.log(error)
            callback(false, [])
        })
    }
}
const registerFcm = (params, callback) => {
    return (dispatch) => {
        AxiosInstance.post(urls.notifications, params).then((response) => {
            callback(true, response.data)
        }).catch(error => {
            console.error(error)
            callback(false, error)
        })
    }
}
const deRegisterFcm = (callback) => {
    return (dispatch) => {
        AxiosInstance.delete(urls.notifications).then((response) => {
            console.log(response.data)
        }).catch(error => {
            console.error(error)
        })
    }
}
export {
    getSportsFixtures,
    getUpcomingFixtures,
    getPastFixtures,
    getFixtureDetails,
    toggleFixtureEnable,
    fetchLeaderboard,
    registerFcm,
    deRegisterFcm
}
