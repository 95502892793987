const Error = ({ error = null }) => {
    return (
        <>
            {
                error && (
                    <span
                        style={{
                            color: "#C10000",
                            fontSize: 14,
                            fontWeight: 400,
                            fontFamily: "Poppins",
                            float: "left",
                        }}
                    >
                        {error}
                    </span>
                )
            }
        </>
    )
}

export default Error