import AxiosInstance from "./AxiosInstance";
import urls from "./urls";

const redemptionsReportsAction = (params, callback) => {
    return (dispatch) => {
        AxiosInstance.get(urls.redemption_reports, { params }).then((response) => {
            if (response.status === 200 || response.status === 304)
                dispatch({ type: 'SET_REPORT_REDEMPTONS', payload: response.data })
            // callback(true,response.data)
        }).catch(error => {
            console.log(error)
            // callback(false,error.detail)
        })
    }
}
const customersReportsAction = (params, callback) => {
    return (dispatch) => {
        AxiosInstance.get(urls.customer_reports, { params }).then((response) => {
            if (response.status === 200 || response.status === 304)
                dispatch({ type: 'SET_REPORT_CUSTOMERS', payload: response.data })
            // callback(true,response.data)
        }).catch(error => {
            console.log(error)
            // callback(false,error.detail)
        })
    }
}
const redeemCode = (params, callback) => {
    return (dispatch) => {
        AxiosInstance.post(urls.redeem_code, params).then((response) => {
            callback(true, response.data)
        }).catch(error => {
            callback(false, error)
        })
    }
}
export {
    redemptionsReportsAction,
    customersReportsAction,
    redeemCode
}
