
const initialState = {
    "games_played": 0,
    "repeat_users": 0,
    "avg_points": 0,
    "points_redeemed": 0,
    "points_redeemed_value": 0,
    "points_remaining": 0,
    "points_expiring": 0
}

const dashboard = (state = initialState, action) => {
    switch (action.type) {
        case "SET_REDEEMS": return {...action.payload}
        default: return { ...state}
    }
}
export default dashboard