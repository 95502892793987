import { combineReducers } from 'redux';
import dashboard from './dashboard';
import schedules from './schedules';
import auth from './auth'
import reports from './reports'
import account from './myAccount'
// import RewardSystem from './RewardSystem';
const rootReducer = combineReducers({
    dashboard,
    schedules,
    auth,
    reports,
    account
    // RewardSystem
});
export default rootReducer;