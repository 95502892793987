import React from "react";

const ArrowLeftIcon = ({ size = 24, color = "#9F9F9F", ...props }) => {
  return (
    <svg width={size} height={size} viewBox="0 0 17 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M15.75 7.27393L0.75 7.27393" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M9.7002 13.2985L15.7502 7.27451L9.7002 1.24951" stroke="#130F26" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
  );
};

export default ArrowLeftIcon;