import AxiosInstance from "./AxiosInstance";
import urls from "./urls";

const accountAction = (params, callback) => {
    return (dispatch) => {
        AxiosInstance.get(urls.account, params).then((response) => {
            if (response.status === 200 || response.status === 304)
                dispatch({type: 'SET_ACCOUNT', payload: response.data})
              
            console.log(response.data)
          
        }).catch(error => {
            console.log(error)
            
        })
    }
}

const contactAction = (params, callback) => {
    return (dispatch) => {
        AxiosInstance.put(urls.contactUpdate,params).then((response) => {
            // if (response.status === 200 || response.status === 304)
            //     dispatch({type: 'SET_CONTACT', payload: response.data})
            console.log(response.data)
            dispatch(accountAction())
            
            callback(true, response.data)
        }).catch(error => {
            console.log(error)
            callback(false, error.detail)
        })
    }
}

const addressAction = (params, callback) => {
    return (dispatch) => {
        AxiosInstance.put(urls.addressUpdate,params).then((response) => {
            // if (response.status === 200 || response.status === 304)
            //     dispatch({type: 'SET_ADDRESS', payload: response.data})
            console.log(response.data)
            dispatch(accountAction())
            callback(true, response.data)
        }).catch(error => {
            console.log(error)
            callback(false, error.detail)
        })
    }
}

const passwordAction = (params, callback) => {
    return (dispatch) => {
        AxiosInstance.put(urls.passwordUpdate,params).then((response) => {

            console.log(response.data)
            callback(true, response.data)
        }).catch(error => {
            console.log(error)
            callback(false, error.detail)
        })
    }
}


const logoutAction = ( callback) => {
    return (dispatch) => {
        AxiosInstance.post(urls.logout,{}).then((response) => {

            console.log(response.data)
            callback(true)

        }).catch(error => {
            console.log(error)
            callback(false)

        })
    }
}



export{
    accountAction,
    contactAction,
    addressAction,
    passwordAction,
    logoutAction
}
