
const initialState = {
    merchant: [],
    contact: [],
    address: []
      
}

const account = (state = initialState, action) => {
    switch (action.type) {
        case "SET_ACCOUNT": return {...state, merchant: action.payload}
        // case "SET_CONTACT": return {...state, contact: action.payload}
        // case "SET_ADDRESS": return {...state, address: action.payload}

        default: return { ...state}
    }
}
export default account