import React from "react";

const Location1Icon = ({ size = 24, color = "#9F9F9F", ...props }) => {
  return (
    <svg
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.0758 13.632C11.3344 13.632 11.5788 13.5148 11.7408 13.3129C12.1957 12.745 16.1877 7.68976 16.1877 5.1119C16.1877 2.29315 13.8945 0 11.0758 0C8.25702 0 5.96387 2.29315 5.96387 5.1119C5.96387 7.68952 9.95583 12.7447 10.4107 13.3129C10.5728 13.5148 10.8171 13.632 11.0758 13.632ZM11.0758 5.96412C10.6059 5.96412 10.2238 5.58205 10.2238 5.11213C10.2238 4.64221 10.6059 4.26015 11.0758 4.26015C11.5457 4.26015 11.9278 4.64221 11.9278 5.11213C11.9278 5.58205 11.5457 5.96412 11.0758 5.96412Z"
        fill="#9F9F9F"
      />
      <path
        d="M21.2996 22.2959H0.851984C0.381679 22.2959 0 22.6776 0 23.1479C0 23.6182 0.381679 23.9999 0.851984 23.9999H21.2996C21.7699 23.9999 22.1516 23.6182 22.1516 23.1479C22.1516 22.6776 21.7699 22.2959 21.2996 22.2959Z"
        fill="#9F9F9F"
      />
      <path
        d="M1.10171 21.1943C1.26774 21.3609 1.48586 21.444 1.70399 21.444C1.92212 21.444 2.14025 21.3609 2.30627 21.1943L2.55597 20.9448L2.80567 21.1945C2.97169 21.3609 3.18982 21.444 3.40795 21.444C3.62608 21.444 3.84421 21.3609 4.01023 21.1943C4.34284 20.8617 4.34284 20.3224 4.01023 19.9896L3.76072 19.74L4.01042 19.4903C4.34303 19.1577 4.34303 18.6184 4.01042 18.2856C3.67818 17.953 3.13789 17.953 2.80566 18.2856L2.55596 18.5353L2.30627 18.2856C1.97403 17.953 1.43374 17.953 1.10151 18.2856C0.768897 18.6182 0.768897 19.1575 1.10151 19.4903L1.35121 19.74L1.10151 19.9897C0.768897 20.3224 0.768907 20.8617 1.10171 21.1943Z"
        fill="#9F9F9F"
      />
      <path
        d="M5.36197 21.1943C5.52799 21.3609 5.74612 21.444 5.96425 21.444C6.18237 21.444 6.4005 21.3609 6.56652 21.1943L6.81622 20.9448L7.06592 21.1945C7.23195 21.3609 7.45008 21.444 7.6682 21.444C7.88633 21.444 8.10446 21.3609 8.27048 21.1943C8.6031 20.8617 8.6031 20.3224 8.27048 19.9896L8.02097 19.74L8.27067 19.4903C8.60329 19.1577 8.60329 18.6184 8.27067 18.2856C7.93844 17.953 7.39814 17.953 7.06592 18.2856L6.81622 18.5353L6.56652 18.2856C6.23428 17.953 5.69399 17.953 5.36177 18.2856C5.02915 18.6182 5.02915 19.1575 5.36177 19.4903L5.61147 19.74L5.36177 19.9897C5.02915 20.3224 5.02916 20.8617 5.36197 21.1943Z"
        fill="#9F9F9F"
      />
      <path
        d="M12.5302 18.2858C12.1976 17.9532 11.6577 17.9532 11.3255 18.2858L11.076 18.5353L10.8263 18.2856C10.494 17.953 9.95376 17.953 9.62153 18.2856C9.28892 18.6182 9.28892 19.1575 9.62153 19.4903L9.87123 19.74L9.62153 19.9897C9.28892 20.3224 9.28892 20.8617 9.62153 21.1945C9.78775 21.3609 10.0059 21.444 10.224 21.444C10.4421 21.444 10.6603 21.3609 10.8263 21.1943L11.076 20.9448L11.3257 21.1945C11.4917 21.3609 11.7098 21.444 11.928 21.444C12.1461 21.444 12.3638 21.3609 12.5302 21.1943C12.8629 20.8617 12.8629 20.3224 12.5302 19.9896L12.2807 19.74L12.5304 19.4903C12.863 19.1577 12.8631 18.6184 12.5302 18.2858Z"
        fill="#9F9F9F"
      />
      <path
        d="M15.5855 18.2858L15.3358 18.5353L15.0861 18.2856C14.7534 17.953 14.2141 17.953 13.8813 18.2856C13.5487 18.6182 13.5487 19.1575 13.8813 19.4903L14.131 19.74L13.8813 19.9897C13.5487 20.3224 13.5487 20.8617 13.8813 21.1945C14.0479 21.3609 14.2656 21.444 14.4838 21.444C14.7019 21.444 14.9197 21.3609 15.0861 21.1943L15.3358 20.9448L15.5855 21.1945C15.7519 21.3609 15.9696 21.444 16.1877 21.444C16.4059 21.444 16.6236 21.3609 16.79 21.1943C17.1226 20.8617 17.1226 20.3224 16.79 19.9896L16.5405 19.74L16.7902 19.4903C17.1228 19.1577 17.1228 18.6184 16.7902 18.2856C16.4574 17.953 15.9181 17.953 15.5855 18.2858Z"
        fill="#9F9F9F"
      />
      <path
        d="M18.1413 21.1943C18.3077 21.3609 18.5254 21.444 18.7435 21.444C18.9617 21.444 19.1794 21.3609 19.3458 21.1943L19.5955 20.9448L19.8452 21.1945C20.0116 21.3609 20.2294 21.444 20.4475 21.444C20.6656 21.444 20.8834 21.3609 21.0498 21.1943C21.3824 20.8617 21.3824 20.3224 21.0498 19.9896L20.8003 19.74L21.05 19.4903C21.3826 19.1577 21.3826 18.6184 21.05 18.2856C20.7174 17.953 20.178 17.953 19.8452 18.2856L19.5955 18.5353L19.3458 18.2856C19.0132 17.953 18.4739 17.953 18.1411 18.2856C17.8084 18.6182 17.8084 19.1575 18.1411 19.4903L18.3908 19.74L18.1411 19.9897C17.8084 20.3224 17.8085 20.8617 18.1413 21.1943Z"
        fill="#9F9F9F"
      />
    </svg>
  );
};

export default Location1Icon;
