import { createTheme, ThemeProvider } from '@mui/material/styles';
import { store, persistor } from './context';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import RouterConfig from './routes';
const theme = createTheme()
function App() {
  return (
    <div
      className="App"
    >
      <Provider
        store={store}
      >
        <PersistGate
          loading={null}
          persistor={persistor}
        >
          <ThemeProvider
            theme={theme}
          >
            <RouterConfig />
          </ThemeProvider>

        </PersistGate>
      </Provider>
    </div>
  );
}

export default App;
