import { OutlinedInput } from "@mui/material"

const Input = ({
    onChange,
    value,
    sx,
    type = 'text',
    ...props
}) => {

    return (
        <OutlinedInput
            sx={{
                width: '100%',
                height: 45,
                padding: 0,
                paddingRight: '3px',
                // paddingLeft: '10px',
                color: 'var(--input-text-color)',
                fontFamily: 'var(--nu-normal)',
                '& .MuiOutlinedInput-notchedOutline, .MuiOutlinedInput-root:hover': {
                    borderColor: 'var(--input-border-color)',
                    padding: 0
                    // change the border color here
                },
                '& .MuiOutlinedInput-input::placeholder': {
                    color: 'var(--input-placeholder-color)'
                },
                '&.Mui-focused .MuiOutlinedInput-notchedOutline,:hover .MuiOutlinedInput-notchedOutline': {
                    borderColor: 'var(--input-border-color) !important',
                    borderWidth: 1,
                    padding: 0,
                    // paddingLeft:30,
                },
                ...sx
            }}
            value={value}
            onChange={(e) => onChange(e.target.value)}
            type={type}
            className="outlinedInput"
            aria-describedby="outlined-weight-helper-text"
            inputProps={{
                'aria-autocomplete': 'none',
            }}

            {...props}
        />
    )
}

export default Input